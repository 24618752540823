import axios from "axios";
export const BASE_URL = 'http://localhost:4000/api/v1'
//export const BASE_URL = "https://matjars-api-dev.s3c.quest/api/v1"

//  export const BASE_URL = 'https://api-matjars-qa.dspaco.com/api/v1/';
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

instance.interceptors.request.use(
  (config) => {
    // perform a task before the request is sent
    const token = localStorage.getItem("token");
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    return config;
    // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    if (response.data.status === "UnAuth" || response.data.message === "UnAuthorization") {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.replace("/login");
    }
    return response;
  },
  function (error) {
    // Handle error
    if (error.response && error.response.status === 401) {
      logoutUser(); // If unauthorized, log out the user
    }
    return Promise.reject(error);
  }
);

/* instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.status == "UnAuth" || response.data.message == "UnAuthorization") {
      localStorage.removeItem("token");
      window.location.replace("/login");
    } else {
      if (response?.isUnAuthorized) {
        window.location.replace("/login");
        return;
      }

      return response;
    }
    if (response?.isUnAuthorized) {
      window.location.replace("/login");
      return;
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
 */
export default instance;
