import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Col, Avatar, Divider, Row, Menu } from "antd";
import { Setting } from "react-iconly";
import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import { details } from "../../../configs/details";
import { useSelector } from "react-redux";
import store from "../../../redux/store";

export default function HeaderUser() {
  const [image, setImage] = useState("")
  const profileData = useSelector((state) => state.profile.profileData);
  console.log("Outside UseEffect is Calling after Profile Pic Is Updated:", profileData.profilePic)
  store.subscribe(() => {
    const state = store.getState();
    console.log("Stateeeee", state.profile.profilePic)
    setImage(state.profile.profilePic)
    // Update the UI with the new state
  });
  useEffect(() => {
    console.log("UseEffect is Calling after Profile Pic Is Updated:", profileData.profilePic)
  }, [profileData.profilePic]);

  const menu = (
    <Menu>
      <Menu.Item>
        <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">Profile Settings</span>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/account-and-settings"
          className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2"
        >
          View Profile
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/login" className="hp-p1-body" onClick={() => { localStorage.clear() }}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  console.log("%c menu: ", "background: black; color: white", menu);

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar
          src={image ? image : details ? details.profilePic : ''}
          size={40} className="hp-cursor-pointer"
        />
      </Dropdown>
    </Col>
  );
}
