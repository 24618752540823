import { Auth } from "aws-amplify";

async function getAccessToken() {
    let accessToken = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
  
    // Check if the access token is expired
    if (isTokenExpired(accessToken)) {
      console.log("Access token expired, refreshing...");
  
      try {
        // Use the refresh token to get a new access token
        const newAccessToken = await refreshAccessToken(refreshToken);
        localStorage.setItem('token', newAccessToken); // Update the access token in storage
        accessToken = newAccessToken; // Use the new access token
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        // If refreshing fails, log the user out and redirect to login
        logoutUser();
      }
    }
  
    return accessToken;
  }
  
  function isTokenExpired(token) {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split('.')[1])); // Decode the JWT token
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime > payload.exp; // Check if the token is expired
  }
  
  async function refreshAccessToken(refreshToken) {
    try {
      const user = await Auth.currentAuthenticatedUser(); // Get the current user
      const session = await Auth.refreshSession(refreshToken); // Refresh the session using the refresh token
      const newAccessToken = session.getAccessToken().getJwtToken(); // Get the new access token
  
      return newAccessToken; // Return the new access token
    } catch (error) {
      throw new Error("Unable to refresh access token: " + error.message);
    }
  }
  function logoutUser() {
    // Clear tokens from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    
    // Sign out the user from AWS Cognito
    Auth.signOut();
  
    // Redirect to login page
    window.location.replace("/login");
  }
  
  export { getAccessToken, isTokenExpired, refreshAccessToken, logoutUser };