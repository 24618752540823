 import React from "react";
import { Row, Col, Select, Button } from "antd";
import ActionButton from "./../action";
import PageTitle from "./../page-title";
import AvgOrders from "./widgets/avgOrders";
import AwaitingDispatch from "./widgets/awaitingDispatch";
import AwaitingOrders from "./widgets/awaitingOrders";
import Sales from "./widgets/sales";
import Profit from "./widgets/profit";
import Reviews from "./widgets/reviews";
import TopPerformingLocations from "./widgets/topPerformingLocations";
import SalesCard from "./widgets/salesCard";
import ListingCard from "./widgets/listingCard";
import Announcements from "./widgets/announcementsCard";
import TotalOrders from './widgets/TotalOrders'
import AllOrders from "./widgets/allOrders";
import LatestOrders from "./widgets/latestOrders";
import TotalRevenue from "./widgets/TotalRevenue";
import AvgOrder from "./widgets/AvgOrder";
import MonthlyAvgOrder from "./widgets/MonthlyAvgOrder";
import MostRecentOrder from "./widgets/MostRecentOrder";
import AccounMonthlyRecurringRevenueGrowth from "./widgets/AccountMonthlyRecurringRevenueGrowth";
import RealTimeSales from "./widgets/RealTimeSales";
import AccountRetention from "./widgets/AccountRetention";
import NewCustomers from "./widgets/NewCustomers";
import MostRecentOrders from "./widgets/MostRecentOrders";


export default function DashboardSales() {
  return (
    <Row gutter={[32, 0]}>
      <Col span={24}>
        <Row gutter={[8, 0]} justify="space-between" align="middle">
          <Col span={24} md={12}>
              <PageTitle pageTitle="Dashboard"/>
              <PageTitle pageText="Sales"/>
          </Col>
          <Col span={24} md={12}>
              <ActionButton />
          </Col>
        </Row>
      </Col>

      <Col span={24} className="hp-my-32">
        <Row gutter={[32, 12]}>
          <Col span={24}>
            <Row gutter={[12, 32]}>
              <Col xl={4} sm={12} span={24}>
                <AwaitingDispatch />
              </Col>

              <Col xl={4} sm={12} span={24}>
                <AwaitingOrders />
              </Col>
              
              <Col xl={4} sm={12} span={24}>
                <Sales />
              </Col>

              <Col xl={4} sm={12} span={24}>
                <Profit />
              </Col>

              <Col xl={4} sm={12} span={24}>
                <AvgOrders />
              </Col>

              <Col xl={4} sm={12} span={24}>
                <Reviews />
              </Col>
              <Col md={6} sm={12} span={24}>
                <TotalOrders />
              </Col>
              <Col md={6} sm={12} span={24}>
                <TotalRevenue />
              </Col>
              <Col md={6} sm={12} span={24}>
                <AvgOrder />
              </Col>
              <Col md={6} sm={12} span={24}>
                <MonthlyAvgOrder />
              </Col>
              
            </Row>
          </Col>

          <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={14} span={24}>
                <LatestOrders />
              </Col>
              <Col md={10} span={24}>
                <TopPerformingLocations />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col md={8} span={24}>
                <SalesCard />
              </Col>
              <Col md={8} span={24}>
                <AllOrders />
              </Col>
              <Col md={8} span={24}>
                <ListingCard />
              </Col>
              <Col md={24} span={24}>
                <MostRecentOrder />
              </Col>
              <Col md={24} span={24}>
              <AccounMonthlyRecurringRevenueGrowth />
              </Col>
              <Col md={12} span={24}>
              <RealTimeSales />
              </Col>
              <Col md={12} span={24}>
                <AccountRetention />
              </Col>
              <Col md={12} span={24}>
              <NewCustomers />
              </Col>
              <Col md={12} span={24}>
              <MostRecentOrders />
              </Col>
            </Row>
          </Col>

          {/* <Col span={24} className="hp-overflow-hidden">
            <Row gutter={[12, 12]}>
              <Col md={8} span={24}>
                <AllOrders />
              </Col>
              <Col md={16} span={24}>
                <LatestOrders />
              </Col>
            </Row>
          </Col> */}

        </Row>
      </Col>
    </Row>
  );
}
