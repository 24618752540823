import axios, { BASE_URL } from "../configs/apiConfig";
import { details } from "../configs/details";
import { getAccessToken } from "../configs/tokenAccess";
// import { resolve } from './resolve.js';

export async function login(user, pass) {
  return await resolve(axios.post("", { user, pass }).then((res) => res.data));
}


export async function loginCustomHeader(token) {
  return new Promise(async (resolve, reject) => {
    try {
      // Get valid access token
      const accessToken = await getAccessToken();
      
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
}

/* export async function loginCustomHeader(token) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const jsonResponse = await response.json();
      resolve(jsonResponse);
    } catch (err) {
      reject(err);
    }
  });
} */

// getMain

export async function getMain() {
  return await axios.get(`category?page=1&limit=10`).then((res) => res.data);
}

export async function getWithdrawStatics() {
  return await axios.get(`/withdraw/pending`).then((res) => res.data);
}
// parent Category with child category
export async function getCategoryMenu() {
  return await axios
    .get(`/category/menu?page=1&limit=1000000`)
    .then((res) => res.data);
}

export async function getSingleCategory(id) {
  return await axios.get(`/category/${id}`).then((res) => res.data);
}

export async function createCategory(data) {
  return await axios.post(`/category`, { ...data }).then((res) => res.data);
}

export async function updateCategory(data) {
  return await axios.patch(`/category`, { ...data }).then((res) => res.data);
}

export async function getBrand() {
  return await axios.get(`/brand?page=1&limit=1000`).then((res) => res.data);
}

export async function getSingleBrand(id) {
  return await axios.get(`/brand/${id}`).then((res) => res.data);
}

export async function createBrand(data) {
  return await axios.post(`/brand`, { ...data }).then((res) => res.data);
}

export async function updateBrand(data) {
  return await axios.put(`/brand`, { ...data }).then((res) => res.data);
}

export async function getAttribute() {
  return await axios.get(`/attribute`).then((res) => res.data);
}

export async function addAttribute(data) {
  return await axios.post(`/attribute`, data).then((res) => res.data);
}

export async function updateAttribute(data) {
  return await axios.patch(`/attribute`, data).then((res) => res.data);
}

export async function getOneAttribute(id) {
  return await axios.get(`/attribute/${id}`).then((res) => res.data);
}

export async function getWithdrawRequest() {
  return await axios.post(`/withdraw/request`).then((res) => res.data);
}

export async function getWithdrawList(page = 1, limit = 10000) {
  return await axios
    .get(`/withdraw/seller?page=${page}&limit=${limit}`)
    .then((res) => res.data);
}

export async function getProductSeller(page = 1, limit = 10000) {
  return await axios
    .get(`/product/seller?page=${page}&limit=${limit}`)
    .then((res) => res.data);
}

export async function getSingleProduct(id) {
  return await axios.get(`/product/${id}`).then((res) => res.data);
}

export async function createProduct(product) {
  return await axios.post(`/product`, product).then((res) => res.data);
}

export async function editProduct(product) {
  return await axios.patch(`/product`, product).then((res) => res.data);
}

export async function getOrderSeller(limit = 5, status, date1, date2) {
  let query = {};
  if (status) {
    query["status"] = status;
  }
  if (date1 && date2) {
    query["date1"] = date1;
    query["date2"] = date2;
  }
  query["limit"] = limit;
  return await axios
    .get(`/order/seller/`, { params: query })
    .then((res) => res.data);
}

export async function getProducts(q, categoryId, brandId) {
  let query = {};

  if (q) query["q"] = q;
  if (categoryId) query["categoryId"] = categoryId;
  if (brandId) query["brandId"] = brandId;

  return await axios
    .get(`/product?page=1&limit=10000`, { params: query })
    .then((res) => res.data);
}

export async function getNavigation(params) {
  return await axios.get(`/navigation`, { params }).then((res) => res.data);
}

export async function getPages(params) {
  return await axios.get(`/page`, { params }).then((res) => res.data);
}

export async function getSinglePage(id) {
  return await axios.get(`/page/${id}`).then((res) => res.data);
}

export async function createPages(data) {
  return await axios.post(`/page`, { ...data }).then((res) => res.data);
}

export async function updatePage(data) {
  return await axios.patch(`/page`, { ...data }).then((res) => res.data);
}

export async function getShipping(params) {
  return await axios.get(`/shipping`, { params }).then((res) => res.data);
}

export async function getSingleShipping(id) {
  return await axios.get(`/shipping/${id}`).then((res) => res.data);
}

export async function createShipping(data) {
  return await axios.post(`/shipping`, { ...data }).then((res) => res.data);
}

export async function updateShipping(data) {
  return await axios.patch(`/shipping`, { ...data }).then((res) => res.data);
}

export async function getOnePage(slug) {
  return await axios.get(`/page/${slug}`).then((res) => res.data);
}

export async function getTickets(params) {
  return await axios.get("/ticket", { params }).then((res) => res.data);
}

export async function getSingleTicket(id) {
  return await axios.get(`/ticket/${id}`).then((res) => res.data);
}

export async function createTicket(body) {
  return await axios.post(`/ticket`, body).then((res) => res.data);
}

export async function getProductReviewSeller(
  page = 1,
  limit = 10000,
  rating,
  status
) {
  let query = {};
  if (rating) {
    query["ratingStar"] = rating;
  }
  if (status) {
    query["status"] = status;
  }
  return await axios
    .get(`/review/seller?page=${page}&limit=${limit}`, { params: query })
    .then((res) => res.data);
}

export async function getCoupons(page = 1, limit = 1000) {
  return await axios
    .get(`/coupon`, { params: { page, limit } })
    .then((res) => res.data);
}

export async function createCoupon(data) {
  return await axios.post(`/coupon`, { ...data }).then((res) => res.data);
}

export async function updateCoupon(data) {
return await axios.put(`/coupon`, { ...data }).then((res) => res.data);
}

export async function getSingleCoupon(id) {
  return await axios.get(`/coupon/${id}`).then((res) => res.data);
}

export async function getUsers(params) {
  return await axios.get("/user", { params }).then((res) => res.data);
}
export async function updateProfile(body) {
  return await axios.patch("/user", {...body}).then((res) => res.data);
}
// Update Seller Status
export async function updateStatus(sellerId) {
  return await axios.patch(`/seller/${sellerId}/status/approve`).then((res) => res.data);
}
export async function getSellers(params) {
  return await axios.get("/seller", { params }).then((res) => res.data);
}

export async function getSetting() {
  return await axios.get("/setting").then((res) => res.data);
}

export async function getCourier(params) {
  return await axios.get("/courier", { params }).then((res) => res.data);
}

export async function getOneOrderByPidandOId(orderId, productId) {
  return await axios
    .get(`/order/product?orderId=${orderId}&productId=${productId}`)
    .then((res) => res.data);
}

export async function updateOrderStatus(orderId, productId, status) {
  return await axios
    .post(`/order/${orderId}/product/${productId}/status/${status}`)
    .then((res) => res.data);
}

export async function updateSettings(data) {
  return await axios.patch("/setting", { ...data }).then((res) => res.data);
}

export async function getKnowledgeBaseCategoies() {
  return await axios
    .get(`/knowledgebase/category?page=1&limit=1000`)
    .then((res) => res.data);
}
